<template>
  <asom-tooltip v-if="pendingRequestCount > 0" :tooltip-text="pendingRequestTooltip" placement="bottom">
    <template #toggleText>
      <span class="relative inline-block" @click="$emit('toggle')">
        <asom-icon icon="software-upload" />
        <span
            class="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
          >
            {{countString}}
          </span>
      </span>
    </template>
  </asom-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  emits: [ 'toggle' ],
  computed: {
    ...mapGetters({
      pendingRequestCount: "offlineData/pendingSyncLength"
    }),
    pendingRequestTooltip() {
      return "Pending sync data";
    },
    countString() {
      return this.pendingRequestCount < 10 ? this.pendingRequestCount : '10+'
    }
  }
}
</script>

<style>

</style>