<template>
  <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow no-print">
    <button
      @click="$emit('toggle:menu')"
      class="px-4 focus:outline-none md:hidden"
    >
      <span class="sr-only">Open sidebar</span>
      <svg
        class="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
    </button>

    <div class="AsomTopbar  sm:px-6 lg:px-8">
      <!-- Start: Sub Menu Navigator -->
      <div class="AsomSubMenu--wrapper">
        <div class="lg:visible flex items-center h-full">
          <label for="tabs" class="sr-only">Select a tab</label>
          <asom-dropdown v-if="links.length > 0" position="bottom-left">
            <div
              class="origin-top-left text-left absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="navbar-menu"
            >
              <template v-for="(link, i) in links" :key="i">
                <router-link
                  :to="link.href"
                  custom
                  v-slot="{ href, isExactActive }"
                >
                  <a
                    :href="href"
                    :class="[
                      'group',
                      'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100',
                      isExactActive && 'text-indigo-600 hover:text-indigo-600',
                    ]"
                    role="menuitem"
                  >
                    <span>{{ link.text }}</span>
                  </a>
                </router-link>
              </template>
            </div>
            <template #toggle="{ click }">
              <div>
                <button
                  @click="click"
                  class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  id="navbar-menu"
                  aria-haspopup="true"
                >
                  <span>Menu</span>
                </button>
              </div>
            </template>
          </asom-dropdown>
        </div>

        <div class="hidden lg:block h-full">
          <nav class="AsomSubMenu" aria-label="Tabs">
            <template v-for="(link, i) in links" :key="i">
              <router-link
                :to="link.href"
                custom
                v-slot="{ href, isExactActive }"
              >
                <a
                  :href="href"
                  :class="[
                    'group',
                    'AsomSubMenu__Link',
                    isExactActive && 'AsomSubMenu__Link--current',
                  ]"
                >
                  <asom-icon
                    v-if="link.icon"
                    class="AsomSubMenu__Link--svg"
                    :icon="link.icon"
                  />
                  <span>{{ link.text }}</span>
                </a>
              </router-link>
            </template>
          </nav>
        </div>
      </div>
      <!-- End: Sub Menu Navigator -->

      <div class="ml-4 flex items-center md:ml-6">
        <asom-tooltip :tooltip-text="apiStatusTooltip" placement="bottom">
          <template #toggleText>
            <div
              class="status-icon mb-1"
              :class="{
                'color-online': !apiCheckingStatus && isAllApiOnline,
                'color-offline': !apiCheckingStatus && !isAllApiOnline,
                'color-checking': apiCheckingStatus,
                'animate-ping': apiCheckingStatus,
              }"
            ></div>
          </template>
        </asom-tooltip>
        <notification-dropdown @toggle="$emit('toggle:notification')">
        </notification-dropdown>
        <pending-sync-data-icon @toggle="viewPendingRequests" />
          
        <!-- Profile dropdown -->
        <div class="mx-4 relative">
          <asom-dropdown
            @toggle="$emit('toggle:profile')"
            position="bottom-right"
          >
            <div
              class="origin-top-right items-center absolute right-0 w-72 rounded-md border-none shadow-lg bg-white ring-1 ring-gray-500 ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <a
                class="text-center block px-4 py-2 text-base font-bold text-gray-700 hover:bg-gray-100"
                role="menuitem"
                >{{ userName }} ({{personNo}})</a
              >
              <hr />
              <div class="profile-item">
                <div class="profile-item-icon">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <div class="profile-item-text-wrap">
                  <span class="profile-item-text">
                    {{ role }}
                  </span>
                </div>
              </div>
              <div v-if="line" class="profile-item">
                <div class="profile-item-icon">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </div>
                <div class="profile-item-text-wrap">
                  <span class="profile-item-text">
                    {{ line }}
                  </span>
                </div>
              </div>
              <div v-if="station" class="profile-item">
                <div class="profile-item-icon">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                    />
                  </svg>
                </div>
                <div class="profile-item-text-wrap">
                  <span class="profile-item-text">
                    {{ station }}
                  </span>
                </div>
              </div>
              <!-- <div v-if="shift" class="profile-item">
                <div class="profile-item-icon">
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                    />
                  </svg>
                </div>
                <div class="profile-item-text-wrap">
                  <span class="profile-item-text">
                    {{ shift }}
                  </span>
                </div>
              </div> -->
              <a
                @click="loginMobile"
                v-if="isMobile"
                class="bg-gray-100 flex flex-cols text-left font-semibold cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 border-b-2"
                role="menuitem"
              >
                <asom-icon icon="log-in" />
                <span class="pl-6">Sign in for mobile</span>
              </a>
              <a
                @click="logout"
                class="bg-gray-100 flex flex-cols text-left font-semibold cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                role="menuitem"
              >
                <asom-icon icon="log-out" />
                <span class="pl-6">Sign out</span>
              </a>
            </div>
            <template #toggle="{ click }">
              <div>
                <button
                  @click="click"
                  class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  id="user-menu"
                  aria-haspopup="true"
                >
                  <span class="sr-only">Open user menu</span>
                  <picture>
                    <source srcset="../assets/images/default-user.webp" type="image/webp" />
                    <img
                      class="h-8 w-8 rounded-full"
                      src="../assets/images/default-user.png"
                      alt="user"
                    />
                  </picture>
                </button>
              </div>
            </template>
          </asom-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { HEALTH_CHECK_INTERVAL } from "../constants/values";
import AsomDropdown from "@/components/dropdown/AsomDropdown";
import AsomIcon from "@/components/icon/AsomIcon";
import NotificationDropdown from "./NotificationDropdown";
import PendingSyncDataIcon from './PendingSyncDataIcon.vue';

export default {
  name: "AsomNavbar",
  components: {
    AsomDropdown,
    AsomIcon,
    NotificationDropdown,
    PendingSyncDataIcon,
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      polling: null,
    };
  },
  mounted() {
    this.healthCheck();
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  computed: {
    ...mapGetters({
      shift: "currentRoster/currentRosterShift",
      token: 'auth/token',
      userId: 'auth/userId',
      userName: "auth/userName",
      personNo: "auth/personNo",
      role: "auth/userRole",
      line: "auth/userInitLineName",
      station: "auth/userStationName",
      isAllApiOnline: "apiStatus/isAllApiOnline",
      apiCheckingStatus: "apiStatus/apiCheckingStatus",
      apiStatusError: "apiStatus/apiStatusError",
    }),
    apiStatusTooltip() {
      if (this.apiCheckingStatus) {
        return 'Checking server status...'
      }
      if (this.isAllApiOnline)
        return 'Server connected'
      return 'Cannot connect to server.'
    },
    isMobile() {
      return window.matchMedia("only screen and (max-width: 760px)").matches;
    }
  },
  emits: ["toggle:menu", "toggle:profile", "toggle:notification"],
  methods: {
    ...mapActions({
      getConnectionStatus: `apiStatus/getConnectionStatus`,
    }),
    viewPendingRequests() {
      this.$router.push({name: "Offline Pending Requests"});
    },
    healthCheck() {
      this.polling = setInterval(() => {
        this.getConnectionStatus();
      }, HEALTH_CHECK_INTERVAL);
    },
    loginMobile() {
      this.$router.push({ name: "Login Mobile Success", query: { 
        token: this.token,
        id: this.userId,
        'access-key': process.env.VUE_APP_MOBILE_ACCESS_KEY
      }});
    },
    async logout() {
      const result = await this.$store.dispatch("logout");
      if (result.success) {
        this.$store.dispatch("clearData");
        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>

<style scoped>
.profile-item {
  @apply flex pt-1.5 grid-flow-col auto-cols-max md:auto-cols-min content-center;
}
.profile-item-icon {
  @apply flex flex-wrap content-center pl-4;
}
.profile-item-text-wrap {
  @apply flex flex-auto content-center pl-3;
}
.profile-item-text {
  @apply block text-sm px-4 py-2 text-xs text-gray-700;
}
.AsomTopbar {
  @apply flex-1 flex justify-between items-center;
}
.AsomTitle {
  @apply text-xl font-semibold leading-6 text-gray-500;
}
.AsomMain__Container {
  @apply overflow-y-auto py-8 flex-1 relative  focus:outline-none;
}

/* Sub Menu */
.AsomSubMenu--wrapper {
  @apply h-full;
}
.AsomSubMenu {
  @apply -mb-px flex space-x-8 h-full;
}
.AsomSubMenu__Link {
  @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 inline-flex items-center py-3 px-1 border-b-2 font-medium text-sm;
}
.AsomSubMenu__Link.AsomSubMenu__Link--current {
  @apply border-indigo-500 text-indigo-600 hover:text-indigo-600 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm;
}
.AsomSubMenu__Link--svg {
  @apply text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-2 h-5 w-5;
}
.AsomSubMenu__Link.AsomSubMenu__Link--current .AsomSubMenu__Link--svg {
  @apply text-indigo-500 -ml-0.5 mr-2 h-5 w-5;
}
.status-icon {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  margin-right: 20px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 1);
}
.color-online {
  box-shadow: inset 0px 0px 10px 2px rgba(0, 255, 182, 0.5),
    0px 0px 10px 2px rgba(0, 255, 135, 1);
}
.color-offline {
  box-shadow: inset 0px 0px 10px 2px rgba(255, 0, 0, 0.5),
    0px 0px 10px 2px rgb(255, 0, 0);
}
.color-checking {
  box-shadow: inset 0px 0px 10px 2px rgba(255, 208, 0, 0.5),
    0px 0px 10px 2px rgb(255, 94, 0);
}
</style>
