<template>
  <asom-alert variant="warning" v-if="!isOnline" title="App is offline">
    <p class="text-sm pt-1">Unable to connect to the server. Some functionalities of the app will be limited.</p>
    <p class="text-sm">Please check your internet connection before continue.</p>
  </asom-alert>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      'isOnline': "apiStatus/isAllApiOnline"
    })
  }
}
</script>

<style>

</style>