<template>
  <div class="AsomLayout font-body" :class="{ collapsed: hideDesktopNavBar }">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div class="md:visible print:invisible">
      <div v-if="showMobileMenu" class="fixed inset-0 flex z-40">
        <transition name="AsomLayout__MobileMenuOverlay">
          <div v-if="showMobileMenu" class="fixed inset-0" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
        </transition>
        <transition name="AsomLayout__MobileMenu">
          <!-- Start: Sidebar Mobile -->
          <div v-if="showMobileMenu" class="AsomSidebar__Mobile">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                @click="toggleMobileMenu(false)"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span class="sr-only">Close sidebar</span>
                <svg
                  class="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div
              class="AsomLogo__WrapperMobile flex-shrink-0 flex items-center px-4"
            >
              <picture class="h-12 AsomLogo__ImageMobile">
                <source srcset="../assets/images/asoms-logo-white.webp" type="image/webp" />
                <img
                  class="h-12 AsomLogo__ImageMobile"
                  src="../assets/images/asoms-logo-white.png"
                  alt="ASOMS"
                />
              </picture>
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <!-- Start: Mobile Navigation-->
              <nav class="AsomNav space-y-1">
                <template v-for="(link, i) in menuLinks" :key="i">
                  <router-link
                    :to="get(link, 'href', '')"
                    custom
                    v-slot="{ href, isExactActive }"
                  >
                    <a
                      :href="href"
                      :class="[
                        'group',
                        'AsomNav__Link',
                        isExactActive && 'AsomNav__Link--current',
                      ]"
                      role="menuitem"
                      @click="toggleMobileMenu(false)"
                    >
                      <asom-icon
                        v-if="get(link, 'icon', false)"
                        class="AsomNav__Icon"
                        :icon="get(link, 'icon.attributes.icon', '')"
                      />
                      <span>{{ link.title }}</span>
                    </a>
                  </router-link>
                  <ul v-if="link.child" class="pl-4">
                    <template
                      v-for="(childLink, j) in link.child"
                      :key="`${i}_${j}`"
                    >
                      <li>
                        <router-link
                          :to="get(childLink, 'href', '')"
                          custom
                          v-slot="{ href, isExactActive }"
                        >
                          <a
                            :href="href"
                            :class="[
                              'group',
                              'AsomNav__Link',
                              isExactActive && 'AsomNav__Link--current',
                            ]"
                            role="menuitem"
                            @click="toggleMobileMenu(false)"
                          >
                            <asom-icon
                              v-if="get(childLink, 'icon', false)"
                              class="AsomNav__Icon"
                              :icon="get(childLink, 'icon.attributes.icon', '')"
                            />
                            <span>{{ childLink.title }}</span>
                          </a>
                        </router-link>
                        <ul v-if="childLink.child" class="pl-4">
                          <template
                            v-for="(childLink, j) in childLink.child"
                            :key="`${i}_${j}`"
                          >
                            <li>
                              <router-link
                                :to="get(childLink, 'href', '')"
                                custom
                                v-slot="{ href, isExactActive }"
                              >
                                <a
                                  :href="href"
                                  :class="[
                                    'group',
                                    'AsomNav__Link',
                                    isExactActive && 'AsomNav__Link--current',
                                  ]"
                                  role="menuitem"
                                  @click="toggleMobileMenu(false)"
                                >
                                  <asom-icon
                                    v-if="get(childLink, 'icon', false)"
                                    class="AsomNav__Icon"
                                    :icon="get(childLink, 'icon', '')"
                                  />
                                  <span>{{ childLink.title }}</span>
                                </a>
                              </router-link>
                            </li>
                          </template>
                        </ul>
                      </li>
                    </template>
                  </ul>
                </template>
              </nav>
              <!-- End: Mobile Navigation-->
            </div>
          </div>
          <!-- End: Sidebar Mobile -->
        </transition>

        <div
          v-if="showMobileMenu"
          class="flex-shrink-0 w-14"
          aria-hidden="true"
        >
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </div>
    <!-- DESKTOP MENU -->
    <div class="hidden md:flex md:flex-shrink-0 print:invisible">
      <sidebar-menu
        v-model:collapsed="hideDesktopNavBar"
        widthCollapsed="60px"
        :menu="menuLinks"
        :disableHover="false"
      >
        <template v-slot:header>
          <div class="AsomLogo__WrapperDesktop">
            <picture
              class="AsomLogo__ImageDesktop"
              v-if="!hideDesktopNavBar"
            >
              <source srcset="../assets/images/asoms-logo-white.webp" type="image/webp" />
              <img
                class="AsomLogo__ImageDesktop"
                src="../assets/images/asoms-logo-white.png"
                alt="SMRT Corp"
              />
            </picture>
          </div>
        </template>
      </sidebar-menu>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden" id="AsomLayout__Main">
      <asom-navbar @toggle:menu="toggleMobileMenu(true)" :links="navbarLinks" />
      <main class="AsomLayout__MainContent" id="AsomLayout__MainContent">
        <div class="flex-1 h-10 flex justify-between content-center no-print">
          <h1 class="AsomLayout__PageTitle sm:px-0 px-4" v-if="pageTitle">
            {{ pageTitle }}
          </h1>
          <span
            class="relative z-0 inline-flex shadow-sm rounded-md"
            v-if="showSelectedStation"
          >
            <span
              class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-blue-600"
            >
              {{ splitLine() + " " + stationName }}
            </span>
            <button
              @click="changeStation"
              type="button"
              class="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-blue-600 hover:text-white hover:bg-button"
            >
              Change Station
            </button>
          </span>
        </div>
        <asom-breadcrumb
          class="no-print mt-3 px-4 md:px-0"
          v-if="pageBreadcrumbs"
          :links="pageBreadcrumbs"
        />
        <div class="AsomMainContent--container space-y-4 px-4 md:px-0">
          <offline-alert />
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import get from "lodash.get";
import remove from "lodash.remove";
import { mapGetters } from "vuex";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import AsomNavbar from "@/layout/AsomNavbar.vue";
import OfflineAlert from "./OfflineAlert.vue";
import getMenuOfRole from "@/constants/menuLinks";
import getBreadcrumbFromRoute from "@/helpers/getBreadcrumbFromRoute";
import { GetSplitLine } from "../services/smrtResource.service";

export default {
  name: "AsomLayout",
  components: {
    AsomNavbar,
    SidebarMenu,
    OfflineAlert,
  },
  props: {
    title: {
      type: String,
    },
    breadcrumbs: {
      type: Array,
      validate: (arr) =>
        arr.every(
          (item) =>
            !!item &&
            typeof item.href === "string" &&
            typeof item.text === "string"
        ),
    },
    navbarLinks: {
      type: Array,
      default: () => [],
    },
    showSelectedStation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let menuLinks = getMenuOfRole(this.$store.state.auth.roleKey);
    const lineName = get(this.$store.state.auth, "line.lineName", "");
    if (lineName !== "Circle Line") {
      menuLinks = remove(menuLinks, function (item) {
        return get(item, "title", "") !== "ATOMS";
      });
    }
    return {
      menuLinks,
      lineName,
      showMobileMenu: false,
      showManpowerScreens: false,
      showCashManagementScreens: false,
    };
  },
  computed: {
    ...mapGetters({
      stationName: "selectedStation/name",
      selectedLineId: "selectedStation/lineId",
      lineOptions: 'smrtResource/lineOptions',
    }),
    hideDesktopNavBar: {
      get() {
        return this.$store.state.display.collapseSideBar;
      },
      set(newValue) {
        this.$store.commit("display/setCollapseSideBar", newValue);
      },
    },
    pageTitle() {
      if (this.title) return this.title;
      let metaTitle = get(this.$route, "meta.title");
      if (metaTitle) return this.$t(metaTitle);
      return "";
    },
    pageBreadcrumbs() {
      if (this.breadcrumbs) return this.breadcrumbs;
      return getBreadcrumbFromRoute(this.$route, this.$t);
    },
  },
  methods: {
    get,
    toggleMobileMenu(value) {
      this.showMobileMenu = value;
    },
    changeStation() {
      this.$router.push({
        name: "Change Current Station",
        query: {
          redirect: this.$route.href,
        },
      });
    },
    splitLine() {
      const line = this.lineOptions.find(({ value }) => value === this.selectedLineId);
      return GetSplitLine(this.stationName, get(line, 'label', ''));
    },
  },
};
</script>

<style src="./desktop_menu.css"></style>
<style>
@media (min-width: 768px) {
  .AsomLayout {
    padding-left: 290px;
    transition: 0.3s ease;
    @apply print:pl-0;
  }
}
@media (min-width: 768px) {
  .AsomLayout.collapsed {
    padding-left: 60px;
    @apply print:pl-0;
  }
}

.AsomLayout {
  /*@apply h-screen flex overflow-hidden bg-gray-100 font-body text-left;*/
  @apply h-screen flex overflow-hidden bg-gray-100 text-left;
}

.AsomLayout__PageTitle {
  @apply text-xl font-semibold leading-6 text-gray-900;
}

.AsomLayout__MainContent {
  scroll-behavior: smooth;
  @apply overflow-y-auto py-8 flex-1 relative  focus:outline-none sm:px-6 lg:px-8;
}

.AsomMainContent--container {
  @apply mt-10 text-left;
}

/** Menu */
.AsomSidebar__Mobile {
  @apply relative flex-1 flex flex-col max-w-xs w-full bg-gray-800;
}

.AsomLayout__MobileMenu-enter-active {
  @apply transition ease-in-out duration-300 transform;
}
.AsomLayout__MobileMenu-enter-from {
  @apply -translate-x-full;
}
.AsomLayout__MobileMenu-enter-to {
  @apply translate-x-0;
}
.AsomLayout__MobileMenu-leave-active {
  @apply transition ease-in-out duration-300 transform;
}
.AsomLayout__MobileMenu-leave-from {
  @apply -translate-x-full;
}
.AsomLayout__MobileMenuOverlay-leave-to {
  @apply translate-x-0;
}
.AsomLayout__MobileMenuOverlay-enter-active {
  @apply transition-opacity ease-linear duration-300;
}
.AsomLayout__MobileMenuOverlay-enter-from {
  @apply opacity-0;
}
.AsomLayout__MobileMenuOverlay-enter-to {
  @apply opacity-100;
}
.AsomLayout__MobileMenuOverlay-leave-active {
  @apply transition-opacity ease-linear duration-300;
}
.AsomLayout__MobileMenuOverlay-leave-from {
  @apply opacity-100;
}
.AsomLayout__MobileMenuOverlay-leave-to {
  @apply opacity-0;
}

.AsomSidebar {
  @apply flex flex-col h-0 flex-1;
}
.AsomLogo__WrapperDesktop,
.AsomLogo__WrapperMobile {
  @apply flex items-center flex-shrink-0 p-4 bg-gray-800;
}
.AsomLogo__ImageDesktop,
.AsomLogo__ImageMobile {
  @apply h-20 w-auto m-auto;
}
.AsomNavMain {
  @apply px-2 py-4 bg-gray-800;
}

.AsomLayout.collapsed .AsomLogo__WrapperDesktop {
  height: 64px;
}

/** Others */
.AsomNav {
  @apply flex-1 px-2 py-4 bg-gray-800;
}
.AsomNav__Link,
.AsomNav__Link__Mobile {
  @apply text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md;
}
.AsomNav__Link--current,
.AsomNav__Link_Mobile--current {
  @apply bg-gray-900 text-white;
}
.AsomNav__Icon {
  @apply text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6;
}
.AsomNav__Link--current .AsomNav__Icon,
.AsomNav__Link_Mobile--current .AsomNav__Icon {
  @apply text-gray-300;
}
/** End Menu */
</style>
