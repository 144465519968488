import { createApp } from "vue";
import "css.gg/icons/all.css";
import { SetupCalendar, DatePicker } from 'v-calendar';
import App from "./App.vue";
import components from "./components";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./stylesheets/tailwind.before.css";
import "./stylesheets/tailwind.after.css";
import "./stylesheets/application.css";
import scrollTopPlugin from "./helpers/scrollTopPlugin";
import AsomLayout from "@/layout/AsomLayout.vue";
import AsomGrid from "@/layout/AsomGrid.vue";
import "./registerServiceWorker";
import VueDOMPurifyHTML from 'vue-dompurify-html';

// Currently init data is reloaded everytime app is refreshed
store.dispatch("loadDataFromStorage").then(() => {
  store.dispatch("display/setup");
  store.dispatch('apiStatus/getConnectionStatus');
  const app = createApp(App);
  app.use(SetupCalendar, {});
  app.component('v-date-picker', DatePicker);
  Object.keys(components).forEach((componentName) => {
    app.component(componentName, components[componentName]);
  });
  app.component("asom-layout", AsomLayout);
  app.component("asom-grid", AsomGrid);
  app.use(store);
  app.use(router);
  app.use(i18n);
  app.use(scrollTopPlugin);
  app.use(VueDOMPurifyHTML, {
    namedConfigurations: {
      'svg': {
          USE_PROFILES: { svg: true }
      },
    }
  });
  app.mount("#app");

  store.dispatch("loadInitialData");
});
