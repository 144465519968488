import { createI18n } from 'vue-i18n';
import enContent from './en.json';

const i18n = createI18n({
  // legacy: false,
  locale: 'en',
  messages: {
    en: enContent,
  }
});

export default i18n;