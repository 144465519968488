<template>
  <div :class="[
    'grid',
    `sm:grid-cols-${sm}`,
    `md:grid-cols-${md}`,
    `lg:grid-cols-${lg}`,
    `gap-${gap}`,
  ]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AsomGrid",
  props: {
    sm: {
      type: Number,
      default: 1,
      validator: (value) => value > 0 && value < 12
    },
    md: {
      type: Number,
      default: 2,
      validator: (value) => value > 0 && value < 12
    },
    lg: {
      type: Number,
      default: 2,
      validator: (value) => value > 0 && value < 12
    },
    gap: {
      type: Number,
      default: 4,
    },
  }
}
</script>