<template>
  <asom-dropdown @toggle="onToggle" position="bottom-right">
    <template v-slot:default="{ click }">
      <div
        class="origin-top-right items-center absolute right-0 w-max rounded-md border-none shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        role="notifications"
        aria-orientation="vertical"
        aria-labelledby="user-notifications"
      >
        <div class="w-96 divide-y">
          <a
            class="text-center block px-4 py-2 text-base font-bold text-gray-700"
            role="menuitem"
          >
            Your Notifications
          </a>
          <hr />
          <template v-if="hasNotifications">
            <div>
              <ul class="divide-y divide-gray-200">
                <li v-for="item in formattedList()" :key="item.id">
                  <button
                    :class="[
                      'w-full flex space-x-3  p-4 ',
                      !item.isCancelled && ' cursor-pointer hover:bg-gray-100',
                    ]"
                    @click="
                      click();
                      onNotificationClicked(item);
                    "
                  >
                    <div class="flex-1 space-y-1">
                      <div class="flex justify-between">
                        <h3 class="text-sm text-left font-medium">
                          {{ item.title }}
                        </h3>
                        <p class="text-sm text-right text-gray-500">
                          {{ item.date }}
                        </p>
                      </div>
                      <p class="text-sm text-left text-gray-500">
                        {{ item.content }}
                      </p>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
            <a
              class="bg-gray-100 flex flex-cols justify-center font-semibold cursor-pointer block px-4 py-2 text-sm text-blue-600 hover:bg-gray-200"
              role="menuitem"
              @click="
                click();
                $router.push('/notifications');
              "
            >
              <span class="text-center">View all</span>
            </a>
          </template>
          <div
            v-else
            class="text-center block px-4 py-2 text-sm text-gray-700"
            role="menuitem"
          >
            Empty
          </div>
        </div>
      </div>
    </template>
    <template #toggle="{ click }">
      <div class="pr-4">
        <button
          @click="click"
          class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-600"
        >
          <span class="sr-only">Open notification</span>
          <span class="relative inline-block">
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            <span
              v-if="countUnread > 0"
              class="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
            >
              {{ countUnreadString }}
            </span>
          </span>
        </button>
      </div>
    </template>
  </asom-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import { humanizeUtcDate } from "../helpers/dateTimeHelpers";
import matchNotificationRoute from "../helpers/matchNotificationRoute";

export default {
  name: "NotificationDropdown",
  emits: ["toggle"],
  computed: {
    ...mapGetters({
      notificationList: "notifications/notificationList",
    }),
    hasNotifications() {
      return this.notificationList.length > 0;
    },
    countUnread() {
      return this.notificationList.filter(({ isRead }) => !isRead).length;
    },
    countUnreadString() {
      return this.countUnread < 10 ? this.countUnread : "10+";
    },
  },
  methods: {
    formattedList() {
      return this.notificationList
        .map((n) => ({
          ...n,
          date: humanizeUtcDate(n.dateCreated),
          matchedRoute: matchNotificationRoute(n),
        }))
        .slice(0, 5);
    },
    onToggle(isOpened) {
      this.$emit("toggle", isOpened);
      if (isOpened && this.countUnread > 0) {
        this.$store.dispatch("notifications/markAllRead");
      }
    },
    onNotificationClicked(item) {
      if (item.isCancelled) return;
      this.$router.push(item.matchedRoute);
    },
  },
};
</script>
